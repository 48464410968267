import React, { useState } from 'react';
import { ThemeProvider } from '../contexts/theme';
import { checkWebp } from '../utils/checkWebp';
import heroImgJpg from '../../images/contact_us/hero_img.jpg';
import heroImgWebp from '../../images/contact_us/hero_img.webp';
import { t } from '../../js/common/translations';
import {
  HeroWrapper,
  HeroInfoWrapper,
  Hero,
  HeroContainer,
  HeroTitle,
  Card,
  SvgWrapper,
  CardInfoTitle,
  CardInfoText,
  CardInfoLinks,
  SwiperWrapper,
  HorizontalWrapper
} from './ContactUs.styles';
import {
  Box,
  Container,
  SvgIcon,
  Typography,
  HtmlTranslation,
  Button,
  Dialog,
  Drawer
} from '../UI/elements';
import {
  Swiper,
  CorporateForm,
  LandlordContactForm,
  TenantForm,
  GeneralForm
} from '../UI/modules/';
import casatbHero from '../../images/casatb/contact_us/contact_us_hero.png';

const phonesInfo = {
  whatsapp: t('dictionary.main_whatsup'),
  whatsappFormatted: t('dictionary.main_whatsup_formatted'),
  phone: t('dictionary.main_phone'),
  phoneFormatted: t('dictionary.main_phone_formatted')
};

const ContactUs = ({ recaptchaSiteKey, corporateUrl, landlordUrl, tenantUrl, generalUrl, casatb, currentMember }) => {
  const isWebp = checkWebp();
  const [formOpenLandlord, setFormOpenLandlord] = useState(false);
  const [formOpenLandlordDrawer, setFormOpenLandlordDrawer] = useState(false);
  const [formOpenCorporate, setFormOpenCorporate] = useState(false);
  const [formOpenCorporateDrawer, setFormOpenCorporateDrawer] = useState(false);
  const [formOpenTenant, setFormOpenTenant] = useState(false);
  const [formOpenTenantDrawer, setFormOpenTenantDrawer] = useState(false);
  const [formOpenGeneral, setFormOpenGeneral] = useState(false);
  const [formOpenGeneralDrawer, setFormOpenGeneralDrawer] = useState(false);
  const clickSource = 'contact-';

  const cardInfos = [
    {
      icon: 'key',
      title: t('contact_us.property_owners.title'),
      slug: 'owners',
      description: t('contact_us.property_owners.description'),
      email: t('contact.property_owner_email'),
      buttonText: t('contact_us.property_owners.rent_with_us'),
      buttonType: t('campaign_tracker.button_type.landlord'),
      whatsappNumber: t('contact_us.property_owners.whatsapp_number'),
      class: 'owners-contact',
      drawer: formOpenLandlordDrawer,
      drawerOpen: setFormOpenLandlordDrawer,
      modalButton: setFormOpenLandlord,
      modal: formOpenLandlord,
      drawerInfo: <LandlordContactForm
        clickSource={clickSource}
        url={landlordUrl}
        onClose={() => setFormOpenLandlordDrawer(false)}
        recaptchaSiteKey={recaptchaSiteKey}
        currentMember={currentMember}
      />,
      modalInfo: <LandlordContactForm
        clickSource={clickSource}
        url={landlordUrl}
        onClose={() => setFormOpenLandlord(false)}
        recaptchaSiteKey={recaptchaSiteKey}
        currentMember={currentMember}
      />,
      modalProps: {
        size: 'md',
        title: t('landlords_page.hero.contact_form.title'),
        className: 'whatsapp-link whatsapp-link-content-contact-us-owner'
      },
      whatsappFormatted: t('contact_us.property_owners.whatsapp_formatted')
    },
    {
      icon: 'bag',
      title: t('contact_us.corporate.title'),
      slug: 'corporate',
      description: t('contact_us.corporate.description'),
      email: t('contact.corporate_email'),
      buttonText: t('contact_us.corporate.partner_with_us'),
      buttonType: t('campaign_tracker.button_type.corporate'),
      whatsappNumber: t('contact_us.general.whatsapp_number'),
      class: 'corporate-contact',
      drawer: formOpenCorporateDrawer,
      drawerOpen: setFormOpenCorporateDrawer,
      drawerInfo: <CorporateForm
        clickSource={clickSource}
        url={corporateUrl}
        onCancel={() => setFormOpenCorporateDrawer(false)}
        siteKey={recaptchaSiteKey}
        currentMember={currentMember}
      />,
      modalButton: setFormOpenCorporate,
      modal: formOpenCorporate,
      modalInfo: <CorporateForm
        clickSource={clickSource}
        siteKey={recaptchaSiteKey}
        url={corporateUrl}
        onCancel={() => setFormOpenCorporate}
        currentMember={currentMember}
      />,
      modalProps: {
        size: 'xs',
        title: t('companies_page.hero.cta'),
        className: 'whatsapp-link whatsapp-link-content-contact-us-corporate'
      },
      whatsappFormatted: t('contact_us.corporate.whatsapp_formatted')
    },
    {
      icon: 'user',
      title: t('contact_us.tenant.title'),
      slug: 'tenant',
      description: t('contact_us.tenant.description'),
      email: t('contact.tenant_email'),
      buttonText: t('contact_us.tenant.talk_with_us'),
      buttonType: t('campaign_tracker.button_type.tenant'),
      whatsappNumber: t('contact_us.tenant.whatsapp_number'),
      class: 'tenant-contact',
      drawer: formOpenTenantDrawer,
      drawerOpen: setFormOpenTenantDrawer,
      drawerInfo: <TenantForm
        className="tenant-contact-modal"
        url={tenantUrl}
        siteKey={recaptchaSiteKey}
        onCancel={() => setFormOpenTenantDrawer(false)}
      />,
      modalButton: setFormOpenTenant,
      modal: formOpenTenant,
      modalInfo: <TenantForm
        className="tenant-contact-modal"
        url={tenantUrl}
        siteKey={recaptchaSiteKey}
        currentMember={currentMember}
        onCancel={() => setFormOpenTenant(false)}
      />,
      modalProps: {
        size: 'sm',
        title: t('contact_us.tenant.modal_title'),
        className: 'whatsapp-link whatsapp-link-content-contact-us-tenant'
      },
      whatsappFormatted: t('contact_us.tenant.whatsapp_formatted')
    },
    {
      icon: 'messageLines',
      title: t('contact_us.general.title'),
      slug: 'general',
      description: t('contact_us.general.description'),
      email: t('contact.hello_email'),
      buttonText: t('contact_us.general.say_hello'),
      buttonType: t('campaign_tracker.button_type.contact_us'),
      whatsappNumber: t('contact_us.general.whatsapp_number'),
      class: 'general-contact',
      drawer: formOpenGeneralDrawer,
      drawerOpen: setFormOpenGeneralDrawer,
      drawerInfo: <GeneralForm
        className="general-contact-modal"
        url={generalUrl}
        siteKey={recaptchaSiteKey}
        onCancel={() => setFormOpenGeneralDrawer(false)}
      />,
      modalButton: setFormOpenGeneral,
      modal: formOpenGeneral,
      modalInfo: <GeneralForm
        className="general-contact-modal"
        url={generalUrl}
        siteKey={recaptchaSiteKey}
        currentMember={currentMember}
        onCancel={() => setFormOpenGeneral(false)}
      />,
      modalProps: {
        size: 'xs',
        title: t('contact_us.general.modal_title'),
        className: 'whatsapp-link whatsapp-link-content-contact-us-general'
      },
      whatsappFormatted: t('contact_us.general.whatsapp_formatted')
    }
  ];

  const cardInfoArray = casatb ? cardInfos.slice(1).reverse() : cardInfos;

  return (
    <ThemeProvider>
      <Box width="100%" height="100%" backgroundColor="secondary.light">
        <Container
          disableGutters
          maxWidth="xl"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            pb: 2
          }}>
          <HeroWrapper component="section" className="hero" sx={{ py: { xs: 2, md: 1 }, px: { xs: 1, xl: 0 } }}>
            <HeroContainer>
              <Hero casatb={casatb} component="picture">
                <Hero casatb={casatb} component="img" src={casatb ? casatbHero : (isWebp ? heroImgWebp : heroImgJpg)} alt={`${t('dictionary.alt_img')} hero`} />
              </Hero>
              <HeroInfoWrapper>
                <HeroTitle>{t('contact_us.hero.title')}</HeroTitle>
              </HeroInfoWrapper>
            </HeroContainer>
          </HeroWrapper>
          <Box
            sx={{
              my: 3,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              textAlign: 'center',
              mx: 1
            }}>
            <Typography variant="h2" sx={{ mb: 0.5, fontFamily: 'Cambon' }}>
              <HtmlTranslation text={t('contact_us.would_html')} />
            </Typography>
            <Typography variant="body1"> {t('contact_us.fill_form')}</Typography>
          </Box>
          <SwiperWrapper>
            <Box sx={{
              width: '100%',
              height: '560px'
            }}>
              <Swiper
                style={{ width: '90%', justifyContent: 'center' }}
                slides={cardInfoArray.map((cardInfo, index) => (
                  <Box
                    key={index}
                    component="section"
                    className={cardInfo.class}
                    mx={0.75}
                    mt={0.5}
                    display="flex"
                    justifyContent="center"
                  >
                    <Card>
                      <SvgWrapper>
                        <SvgIcon name={cardInfo.icon} size={26} color='primary.main' />
                      </SvgWrapper>
                      <Box>
                        <CardInfoTitle>{cardInfo.title}</CardInfoTitle>
                        <Box>
                          <CardInfoText>{cardInfo.description}</CardInfoText>
                        </Box>
                      </Box>
                      <Box sx={{
                        width: '100%',
                        px: 0.25,
                        my: 1.75,
                        height: '1px',
                        backgroundColor: 'primary.10'
                      }} />
                      <Box>
                        <Box sx={{ display: 'flex', marginBottom: 1 }}>
                          <SvgIcon name="phone" size={13} color="black" sx={{ marginRight: 0.5 }} />
                          <CardInfoLinks href={`tel:${phonesInfo.phone}`} target="_blank" rel="nofollow">
                            {phonesInfo.phoneFormatted}
                          </CardInfoLinks>
                        </Box>
                        <Box sx={{ display: 'flex', marginBottom: 1 }}>
                          <SvgIcon name="whatsapp" size={13} color="black" sx={{ marginRight: 0.5 }} />
                          <CardInfoLinks
                            target="_blank"
                            href={`/whatsapp_redirect?button_type=${cardInfo.buttonType}&whatsapp_number=${cardInfo.whatsappNumber}`}
                            className={cardInfo.modalProps.className}
                          >
                            {cardInfo.whatsappFormatted}
                          </CardInfoLinks>
                        </Box>
                        <Box height={!cardInfo.email && '1.375rem'} sx={{ display: 'flex', marginBottom: 1 }}>
                          {cardInfo.email && (
                            <CardInfoLinks href={`mailto:${cardInfo.email}`} target="_blank" rel="nofollow">
                              <SvgIcon name="messageLines" size={13} color="black" sx={{ marginRight: 0.5 }} />
                              <Box component="span">{cardInfo.email}</Box>
                            </CardInfoLinks>
                          )}
                        </Box>
                      </Box>
                      <Button className={`${cardInfo.slug}-contact-bt`} onClick={() => cardInfo.drawerOpen(true)}>{cardInfo.buttonText}</Button>
                    </Card>
                    <Drawer
                      anchor="bottom"
                      className={`${cardInfo.slug}-contact-modal`}
                      open={cardInfo.drawer}
                      onClose={() => cardInfo.drawerOpen(false)}
                      headerContent={(
                        <Typography variant="h4">
                          {cardInfo.modalProps.title || ''}
                        </Typography>
                      )}
                    >
                      <Box>
                        {cardInfo.drawerInfo}
                      </Box>
                    </Drawer>
                  </Box>
                ))}
                loop={false}
                spaceBetween={-30}
              />
            </Box>
          </SwiperWrapper>
          <HorizontalWrapper>
            {(cardInfoArray.map((cardInfo, index) => (
              <Box
                key={index}
                component="section"
                className={cardInfo.class}
                mx={0.75}
                my={0.5}
              >
                <Card>
                  <SvgWrapper>
                    <SvgIcon name={cardInfo.icon} size={26} color='primary.main' />
                  </SvgWrapper>
                  <Box>
                    <CardInfoTitle>{cardInfo.title}</CardInfoTitle>
                    <Box>
                      <CardInfoText>{cardInfo.description}</CardInfoText>
                    </Box>
                  </Box>
                  <Box sx={{
                    width: '100%',
                    px: 0.25,
                    my: 1.75,
                    height: '1px',
                    backgroundColor: 'primary.10'
                  }} />
                  <Box>
                    <Box sx={{ display: 'flex', marginBottom: 1 }}>
                      <SvgIcon name="phone" size={13} color="black" sx={{ marginRight: 0.5 }} />
                      <CardInfoLinks href={`tel:${phonesInfo.phone}`} target="_blank" rel="nofollow">
                        {phonesInfo.phoneFormatted}
                      </CardInfoLinks>
                    </Box>
                    <Box sx={{ display: 'flex', marginBottom: 1 }}>
                      <SvgIcon name="whatsapp" size={13} color="black" sx={{ marginRight: 0.5 }} />
                      <CardInfoLinks
                        target="_blank"
                        rel="nofollow"
                        href={`/whatsapp_redirect?button_type=${cardInfo.buttonType}&whatsapp_number=${encodeURIComponent(cardInfo.whatsappNumber)}`}
                        className={cardInfo.modalProps.className}
                      >
                        {cardInfo.whatsappFormatted}
                      </CardInfoLinks>
                    </Box>
                    <Box height={!cardInfo.email && '1.375rem'} sx={{ display: 'flex', marginBottom: 1 }}>
                      {cardInfo.email && (
                        <CardInfoLinks href={`mailto:${cardInfo.email}`} target="_blank">
                          <SvgIcon name="messageLines" size={13} color="black" sx={{ marginRight: 0.5 }} />
                          <Box component="span">{cardInfo.email}</Box>
                        </CardInfoLinks>
                      )}
                    </Box>
                  </Box>
                  <Button className={`${cardInfo.slug}-contact-bt`} onClick={() => cardInfo.modalButton(true)}>{cardInfo.buttonText}</Button>
                </Card>
                <Dialog
                  open={cardInfo.modal}
                  className={`${cardInfo.slug}-contact-modal`}
                  onClose={() => cardInfo.modalButton(false)}
                  maxWidth={cardInfo.modalProps.size}
                  headerContent={(
                    <Typography variant="h4">
                      {cardInfo.modalProps.title || ''}
                    </Typography>
                  )}
                  sx={{ width: 1 }}
                >
                  {cardInfo.modalInfo}
                </Dialog>
              </Box>
            )))}
          </HorizontalWrapper>
        </Container >
      </Box >
    </ThemeProvider >
  );
};

export default ContactUs;
